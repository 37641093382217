<template>
  <TooltipProvider>
    <TooltipRoot :delay-duration="100">
      <TooltipTrigger as-child>
        <slot></slot>
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent side="right" :side-offset="32" avoid-collisions>
          <div class="tooltip">
            <img v-if="file?.filestack_url" :src="thumbnail(file.filestack_url)" class="tooltip__image" />
          </div>
        </TooltipContent>
      </TooltipPortal>
    </TooltipRoot>
  </TooltipProvider>
</template>

<script lang="ts" setup>
import { TooltipProvider, TooltipContent, TooltipPortal, TooltipRoot, TooltipTrigger } from "radix-vue";
import type { SupabaseFile } from "~/utils/supabase.types";
import { useFilestackUtils } from "~/composables/useFilestack";

defineProps<{ file: SupabaseFile }>();

const { thumbnail } = useFilestackUtils();
</script>

<style lang="scss" scoped>
.tooltip {
  display: flex;
  border-radius: 10px;
  box-shadow: var(--shadow-3);
  background: var(--white);

  &__image {
    width: 640px;
    height: 800px;
    margin: 24px;
    border-radius: 10px;
    box-sizing: border-box;
    border: 1px solid var(--gray-6);
    background: var(--white);
    object-fit: cover;
    object-position: left top;

    &:hover {
      border: 1px solid var(--gray-5);
    }

    & {
      // Shiny effect #2
      // Source: https://www.amitmerchant.com/shine-animation-on-hover-using-css/
      -webkit-mask-image: linear-gradient(60deg, black 25%, rgba(0, 0, 0, 0.8) 50%, black 75%);
      mask-image: linear-gradient(60deg, black 25%, rgba(0, 0, 0, 0.8) 50%, black 75%);
      -webkit-mask-size: 400%;
      mask-size: 400%;
      -webkit-mask-position: 100%;
      mask-position: 100%;
      animation: shiny 1.4s ease;
    }

    @keyframes shiny {
      from {
        mask-position: 100%;
        -webkit-mask-position: 100%;
      }
      to {
        mask-position: 0%;
        -webkit-mask-position: 0%;
      }
    }
  }
}
</style>
